const sentryIgnoreErrorMessages = [
    "Network Error",
    "timeout of 0ms exceeded"
];

export function skipSentryError(exception)
{
    const errorMessage = _.get(exception, "message", "");
    const errorCode    = _.get(exception, "request.status", "");

    // check if we have custom `isSentryErrorProcessed` in exception
    if (typeof exception.isSentryErrorProcessed != "undefined") {
        // skip as error already processed
        if (exception.isSentryErrorProcessed === true) {
            return true;
        }

        // marked it as processed here
        exception.isSentryErrorProcessed = true;
    }

    // stop all 4xx errors from passing to Sentry
    if (errorCode >= 400 && errorCode <= 499) {
        return true;
    }

    // skip error without server response (status is zero) and error that should be ignored by error message
    if (errorCode === 0 || _.findIndex(sentryIgnoreErrorMessages, errorMessage) === -1) {
        return true;
    }

    return false;
}

/**
 * When we using `http` module inside Vue component we can have situation when
 * error has been sent via `http` module and then sent again via Sentry Vue integration.
 * To avoid this we are updating original exception with extra `isSentryErrorProcessed`
 * parameter and on next call of the same exception it will be ignored.
 */
export function markSentryError(exception)
{
    exception.isSentryErrorProcessed = false;
}
